import React from 'react';


export default class Articles extends React.Component {
    render() {
        return (
            <h1>
                Articles
            </h1>

        )
    }
}